import templateStyles from './cardTemplateStyles'
import { iframeBuild } from './iframeBuild.js'
import iframeMessenger from './IframeMessenger'

window.StackPay = {

	/**
	 * the API Public Key furnished by Stack Pay
	 * @type {string}
	 */
	apiKey: '',

	/**
	 * The environment local, sandbox, or production
	 * @type {string}
	 */
	env: '',

	/**
	 * URL for Stack Pay API
	 * @type {string}
	 */
	apiUrl: '',

	/**
	 * Server Url application root
	 * @type {string}
	 */
	serverUrl: '',

	/**
   * Checkout URL based on environment
   * @type {string}
   */
	checkoutURL: '',

	/**
	 * Contains the HTML for the card template
	 * @type {string}
	 * @deprecated
	 */
	ccHtml: '',

	/**
	 * @type {IframeMessenger} IframeMessenger instance holder
	 */
	spMessenger: null,

	/**
	 * Variable that contains the Card Iframe element
	 * @type {HTMLIFrameElement}
	 */
	cardIframe: '',

	/**
	 * Variable that contains the Card Iframe element
	 * @type {HTMLIFrameElement}
	 */
	cardV2Iframe: '',

	/**
	 * Variable that contains the Ach Iframe element
	 * @type {HTMLIFrameElement}
	 */
	achIframe: '',

	/**
	 * Variable that contains the ECheck Iframe element
	 * @type {HTMLIFrameElement}
	 */
	eCheckV2Iframe: '',
	/**
	 * Object that contains the the styles to be injected into the iframe.
	 * @type {object}
	 */
	iframeStyles: {},

	/**
	 * Variable containing Card Type
	 * String containing type of card being passed into the form
	 * @type {string}
	 */
	cardType: '',

	/**
	 * Boolean that receives the name validation result
	 * @type {boolean}
	 */
	nameValid: false,

	/**
	 * Boolean that receives the card validation result
	 * @type {boolean}
	 */
	cardIsValid: false,

	/**
	 * Boolean that receives the card expiration validation result
	 * @type {boolean}
	 */
	expirationValid: false,

	/**
	 * Boolean that receives the CVV validation result
	 * @type {boolean}
	 */
	cvvValid: false,

	/**
	 * Boolean that receives the Zip Code validation result
	 * @type {boolean}
	 */
	zipCodeValid: false,

	/**
	 * Boolean that receives the account number validation result
	 * @type {boolean}
	 */
	accountNumberValid: false,

	/**
	 * Boolean that receives the account number verify validation result
	 * @type {boolean}
	 */
	accountNumberVerifyValid: false,

	/**
	 * Boolean that receives the routing number validation result
	 * @type {boolean}
	 */
	routingNumberValid: false,

	/**
	 * Regex that validates user first and last name
	 * @type {RegExp}
	 */
	nameRegex: /^[a-zA-Z]{1,}(?!.*--)[a-zA-Z- ]*[a-zA-Z]?$/,

	/**
	 * Regex that validates email address
	 * @type {RegExp}
	 */
	emailRegex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

	/**
	 * Regex that validates expiry dat,
	 * Further validation exists in validateCardExpiration()
	 * @type {RegExp}
	 */
	monthYearRegex: /^[0-9]{4}(?!.*\.$)$/gi,

	/**
	 * Regex that validates CVV
	 * @type {RegExp}
	 */
	cvvRegex: /^[0-9]{3}$/,

	/**
	 * Regex that validates CVV for Amex cards
	 * @type {RegExp}
	 */
	cvvAmex: /^[0-9]{4}$/,

	/**
	 * Regex that validates US and Canada Zip Codes
	 * @type {RegExp}
	 */
	// zipCodeRegex: /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/,
	zipCodeRegex: /^[0-9]{5}$|^[A-Z][0-9][A-Z](\s|-)?[0-9][A-Z][0-9]$/,

	/**
	 * Regex that validates Visa cards
	 * @type {RegExp}
	 */
	//visaRegex: /^4[0-9]{12}(?:[0-9]{3})(?![a-zA-Z])+?$/,
	visaRegex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,

	/**
	 * Regex that validates Mastercard
	 * @type {RegExp}
	 */
	masterCardRegex: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,

	/**
	 * Regex that validates Amex cards
	 * @type {RegExp}
	 */
	amexRegex: /^3[47][0-9]{13}(?![a-zA-Z])+$/i,

	/**
	 * Regex that validates Discover cards
	 * @type {RegExp}
	 */
	discoverRegex: /^6(?:011|5[0-9]{2})[0-9]{12}(?![a-zA-Z])+$/,

	/**
	 * Regex that validates Diners cards
	 * @type {RegExp}
	 */
	dinersRegex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}(?![a-zA-Z])+$/i,

	/**
	 * Regex that validates JCB cards
	 * @type {RegExp}
	 */
	jcbRegex: /^(?:2131|1800|35\d{3})\d{11}(?![a-zA-Z])+$/,

	/**
	 * Regex that validates bank account number for ach
	 * @type {RegExp}
	 */
	accountNumberRegex: /\b(?![0]\b)^[0-9]{1,30}$/,

	/**
	 * Regex that validates bank routing number for ach
	 * @type {RegExp}
	 */
	routingNumberRegex: /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/,

	/**
	 * Payment method token
	 * @type {string}
	 */
	paymentMethodToken: '',

	/**
	 * Verifies public key and delegates environment
	 * @param {string} publicKey - supplied by Stack Pay
	 * @param {string} mode - either local, sandbox, or production
	 * @returns {Promise<void>}
	 */
	verifyKey: (publicKey, mode = null) => {
		return new Promise((resolve, reject) => {
			if (publicKey === '' || publicKey === null) {
				reject('Public Key is required. ');
			}

			StackPay.setApiKey(publicKey).then(() => resolve(StackPay.setEnv(mode)))
		})
	},

	/**
	 * Set Api Url
	 * @param apiUrl
	 * @returns {string}
	 */
	setAPIUrl: (apiUrl) => {
		return StackPay.apiUrl = apiUrl
	},

	/**
	 * Get Api Url
	 * @returns {string}
	 */
	getApiUrl: () => {
		return StackPay.apiUrl
	},

	/**
	 * Set Stripe Key
	 * @param stripeKey
	 * @returns {string}
	 */
	setStripeKey: (stripeKey) => {
		return StackPay.stripeKey = stripeKey
	},

	/**
	 * Get Stripe Key
	 * @returns {string}
	 */
	getStripeKey: () => {
		return StackPay.stripeKey
	},

	/**
	 * Set serverUrl for the application
	 * The Server URL is set based on the mode
	 * @param mode
	 * @returns {string}
	 */
	setServerUrl: (mode) => {
		switch (mode) {
			case 'local':
				return StackPay.serverUrl = 'https://stackpayjs.local'
				break;
			case 'staging':
				return StackPay.serverUrl = 'https://staging-js.stackpay.com'
				break;
			case 'sandbox':
				return StackPay.serverUrl = 'https://sandbox-js.mystackpay.com'
				break;
			default:
				return StackPay.serverUrl = 'https://js.mystackpay.com'
				break;
		}

		return StackPay.serverUrl
	},

	/**
	 * Get serverUrl for the application
	 * @returns {string}
	 */
	getServerUrl: () => {
		return StackPay.serverUrl
	},

	/**
	 * Evaluates entered public key, and returns a promise with API Key String
	 * @param {string} key
	 * @returns {Promise<String>}
	 */
	setApiKey: (key) => {
		return new Promise((resolve, reject) => {
			StackPay.apiKey = key
			resolve(StackPay.apiKey)
		})
	},

	/**
	 * Set API Key
	 * @returns {string}
	 */
	getApiKey: () => {
		return StackPay.apiKey
	},

	/**
	 * Returns IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	setSpMessenger: () => {
		return StackPay.spMessenger = new iframeMessenger(
			StackPay.getCardIframe().contentWindow,
			StackPay.getServerUrl()
		)
  },

	/**
	 * Returns IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	setSPCardV2Messenger: () => {
		return StackPay.spMessenger = new iframeMessenger(
			StackPay.getCardV2Iframe().contentWindow,
			StackPay.getServerUrl()
		)
  },

  	/**
	 * Returns IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	setSpACHMessenger: () => {
		return StackPay.spMessenger = new iframeMessenger(
      StackPay.getAchIframe().contentWindow,
			StackPay.getServerUrl()
		)
	},

	/**
	 * Returns IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	setSPECheckV2Messenger: () => {
		return StackPay.spMessenger = new iframeMessenger(
			StackPay.getECheckV2Iframe().contentWindow,
			StackPay.getServerUrl()
		)
	},


	/**
	 * Returns IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	setSpVerifiedACHMessenger: () => {
		return StackPay.spMessenger = new iframeMessenger(
			StackPay.getVerifiedAchIframe().contentWindow,
			StackPay.getServerUrl()
		)
	},

	/**
	 * Retrieve IframeMessenger instance
	 * @returns {IframeMessenger}
	 */
	getSpMessenger: () => {
		return StackPay.spMessenger
	},

	/**
	 * Set StackPay.cardIframe with the value of the iframe
	 * @param iframe
	 * @returns {HTMLIFrameElement}
	 */
	setCardIframe: (iframe) => {
		return StackPay.cardIframe = iframe
	},

	/**
	 * Retrieves the iframe element that is initially set
	 * within StackPay.elements.createCardElement()
	 * @returns {HTMLIFrameElement}
	 */
	getCardIframe: () => {
		return StackPay.cardIframe
	},
	/**
	 * Set StackPay.cardV2Iframe with the value of the iframe
	 * @param iframe
	 * @returns {HTMLIFrameElement}
	 */
	setCardV2Iframe: (iframe) => {
		return StackPay.cardV2Iframe = iframe
	},

	/**
	 * Retrieves the iframe element that is initially set
	 * within StackPay.elements.createCardElement()
	 * @returns {HTMLIFrameElement}
	 */
	getCardV2Iframe: () => {
		return StackPay.cardV2Iframe
	},

	/**
	 * Set StackPay.achIframe with the value of the iframe
	 * @param iframe
	 * @returns {HTMLIFrameElement}
	 */
	setAchIframe: (iframe) => {
		return StackPay.achIframe = iframe
	},

	/**
	 * Retrieves the iframe element that is initially set
	 * within StackPay.elements.createAchElement()
	 * @returns {HTMLIFrameElement}
	 */
	getAchIframe: () => {
		return StackPay.achIframe
	},

	/**
	 * Set StackPay.eCheckIframeV2 with the value of the iframe
	 * @param iframe
	 * @returns {HTMLIFrameElement}
	 */
	setECheckV2Iframe: (iframe) => {
		return StackPay.eCheckV2Iframe = iframe
	},

	/**
	 * Retrieves the iframe element that is initially set
	 * within StackPay.elements.createECheckV2Element()
	 * @returns {HTMLIFrameElement}
	 */
	getECheckV2Iframe: () => {
		return StackPay.eCheckV2Iframe
	},

	/**
	 * Set StackPay.achIframe with the value of the iframe
	 * @param iframe
	 * @returns {HTMLIFrameElement}
	 */
	setVerifiedAchIframe: (iframe) => {
		return StackPay.verifiedAchIframe = iframe
	},

	/**
	 * Retrieves the iframe element that is initially set
	 * within StackPay.elements.createVerifiedAchElement()
	 * @returns {HTMLIFrameElement}
	 */
	getVerifiedAchIframe: () => {
		return StackPay.verifiedAchIframe
	},

	/**
	 * Sets the keys (style selectors ) and values ( styles )
	 * for the styles object that will be iterated through, and set
	 * on the iframe inputs
	 * @param {object} styles
	 * @returns {object}
	 */
	setIframeStyles: (styles) => {
		return StackPay.iframeStyles = styles
	},

	/**
	 * Retrieves styles object for iframe
	 * @returns {object}
	 */
	getIframeStyles: () => {
		return StackPay.iframeStyles
	},

	/**
	 * Set name validation result as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setNameValidity: (validity) => {
		return StackPay.nameValid = validity
	},

	/**
	 * Get name validity result as boolean
	 * @returns {boolean}
	 */
	getNameValidity: () => {
		return StackPay.nameValid
	},

	/**
	 * Set email validation result as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setEmailAddressValidity: (validity) => {
		return StackPay.emailValid = validity
	},

	/**
	 * Get email validity result as boolean
	 * @returns {boolean}
	 */
	getEmailAddressValidity: () => {
		return StackPay.emailValid
	},

	/**
	 * Set the credit card type ( visa, mastercard, amex, discover )
	 * @param type
	 * @returns {string}
	 */
	setCardType: (type) => {
		return StackPay.cardType = type
	},

	/**
	 * Return credit card type
	 * @returns {string}
	 */
	getCardType: () => {
		return StackPay.cardType
	},

	/**
	 * Set credit card vaidity result as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setCardValidity: (validity) => {
		return StackPay.cardIsValid = validity
	},

	/**
	 * Retrieve credit card validity result as boolean
	 * @returns {boolean}
	 */
	getCardValidity: () => {
		return StackPay.cardIsValid
	},

	/**
	 * Set expiration date validity result as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setExpirationValidity: (validity) => {
		return StackPay.expirationValid = validity
	},

	/**
	 * Retrieve expiration date validity result as boolean
	 * @returns {boolean}
	 */
	getExpirationValidity: () => {
		return StackPay.expirationValid
	},

	/**
	 * Set CVV validity as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setCvvValidity: (validity) => {
		return StackPay.cvvValid = validity
	},

	/**
	 * Retrieve CVV validity as boolean
	 * @returns {boolean}
	 */
	getCvvValidity: () => {
		return StackPay.cvvValid
	},

	/**
	 * Set zip code validity as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setZipCodeValidity: (validity) => {
		return StackPay.zipCodeValid = validity
	},

	/**
	 * Retrieve zip code validity as boolean
	 * @returns {boolean}
	 */
	getZipCodeValidity: () => {
		return StackPay.zipCodeValid
	},

	/**
	 * Set bank account number validity as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setAccountNumberValidity: (validity) => {
		return StackPay.accountNumberValid = validity
	},

	/**
	 * Set bank account number verify validity as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setAccountNumberVerifyValidity: (validity) => {
		return StackPay.accountNumberVerifyValid = validity
	},

	/**
	 * Retrieve account number validity as boolean
	 * @returns {boolean}
	 */
	getAccountNumberValidity: () => {
		return StackPay.accountNumberValid
	},

	/**
	 * Retrieve account number verify validity as boolean
	 * @returns {boolean}
	 */
	getAccountNumberVerifyValidity: () => {
		return StackPay.accountNumberVerifyValid
	},


	/**
	 * Set bank routing number validity as boolean
	 * @param validity
	 * @returns {boolean}
	 */
	setRoutingNumberValidity: (validity) => {
		return StackPay.routingNumberValid = validity
	},

	/**
	 * Retrieve account number validity as boolean
	 * @returns {boolean}
	 */
	getRoutingNumberValidity: () => {
		return StackPay.routingNumberValid
	},


	/**
	 * Set payment method token from StackPay API
	 * @param token
	 * @returns {string}
	 */
	setTokenValue: (token) => {
		return StackPay.paymentMethodToken = token
	},

	/**
	 * Retrieve payment method token from StackPay API
	 * @returns {string}
	 */
	getTokenValue: () => {
		return new Promise((resolve, reject) => {
			if (StackPay.paymentMethodToken !== '') {
				resolve(StackPay.paymentMethodToken)
			} else {
				reject('token is null')
			}
		})
	},

	/**
	 * Set accepted card type values from StackPay API
	 * @param cardTypesAllowed
	 * @returns {string}
	 */
	setCardTypesAllowedValue: (cardTypesAllowed) => {
		return StackPay.cardTypesAllowed = cardTypesAllowed
	},

	/**
	 * Retrieve payment method token from StackPay API
	 * @returns {string}
	 */
	getCardTypesAllowedValue: () => {
		return new Promise((resolve, reject) => {
			if (StackPay.cardTypesAllowed !== '') {
				resolve(StackPay.cardTypesAllowed)
			} else {
				reject('cardTypesAllowed is null')
			}
		})
	},

	/**
   * Set Checkout URL
   * @param {string} checkoutUrl
   * @returns {string}
   */
	setCheckoutURL: (checkoutUrl) => {
		StackPay.checkoutURL = checkoutUrl;
		return StackPay.checkoutURL;
	  },
	
	  /**
	   * Get Checkout URL
	   * @returns {string}
	   */
	  getCheckoutURL: () => {
		return StackPay.checkoutURL;
	  },


	/**
	 * Reads the env file that is designated by the mode parameter in validateKey() method.
	 * Resolves an object containing key & value of each line in the ENV file.
	 * @param {String} fileUrl
	 * @returns {Promise<Object>}
	 */
	readENVTextFile: (fileUrl) => {
		return new Promise(async (resolve, reject) => {

			const utf8Decoder = new TextDecoder('utf-8')
			let envVariables = []
			if (fileUrl === 'https://stackpayjs.local/settings.stackpayjs.local') {
				envVariables = [
					{ key: 'SERVER_URL', value: 'https://stackpayjs.local' },
					{ key: 'API_URL', value: 'http://localhost:9080' },
					{ key: 'STRIPE_KEY', value: 'pk_test_51ITZODIyHa7Yaf1xEOXkEQPH3uUfRHHP1m3OejJGEqyIVtQxCnV6j8ltYxQzmnwZywaAVsKFyqmWro61DFJKxjXv00DPwbg7Y1' },
					{ key: 'CHECKOUT_URL', value: 'http://localhost:9500' },
				]
			} else {
				const response = await fetch(fileUrl)

				const reader = response.body.getReader()

				let { value: textContent, done: readerDone } = await reader.read()
				textContent = textContent ? utf8Decoder.decode(textContent) : ''

				// Split ENV file by line break
				const re = /\n|\r|\r\n/gm
				let result = re.exec(textContent).input.split("\n")

				for await (let line of result) {
					if (line === '') {
						continue;
					}

					// Split each line by key / value
					let splitLine = line.split('=')
					envVariables.push({
						key: splitLine[0],
						value: splitLine[1]
					})
				}
			}

			resolve(envVariables)
		}).catch(error => console.log('Error Reading selected ENV: ', error))

	},

	/**
	 * Sets ENV variable, and assigns the proper value to the StackPay.apiUrl
	 * If mode parameter is null or empty, then ENV defaults to Production
	 * @param {string} mode
	 * @returns {Promise<void>}
	 */
	setEnv: async (mode = null) => {
		let modeString = mode === null || mode === ''
			? 'production'
			: mode

		StackPay.env = modeString
		let envUrl = StackPay.setServerUrl(StackPay.env = modeString) + '/settings.stackpayjs'
		if (modeString !== 'production') {
			envUrl = envUrl + '.' + modeString
		}

		await StackPay.readENVTextFile(envUrl).then(result => {
				StackPay.setAPIUrl(result[1].value)
				StackPay.setStripeKey(result[2].value)
				StackPay.setCheckoutURL(result[3].value);
			})
	},

	/**
	 * Retrieve ENV environment string:
	 * local, staging, sandbox, production
	 * @returns {string}
	 */
	getEnv: () => {
		return StackPay.env
	},

	/**
	 * Style the iframe by receiving style object on load
	 * @param styleObj
	 */
	styleCardWindow: (styleObj) => {
		templateStyles(styleObj)
	},

	/**
	 * Takes the serialized form data and returns a json object so
	 * that it can be consumed by getPaymentMethodToken()
	 * @param {object} options
	 * @returns {object}
	 */
	serializeFormData: (options) => {
		return {
			name: options[0]['value'],
			accountType: options[1]['value'],
			cardNumber: options[2]['value'],
			expiration: options[3]['value'],
			cvv: options[4]['value'],
			zipcode: options[5]['value']
		}
	},

	/**
	 * Takes the serialized form data and returns a json object so
	 * that it can be consumed by getPaymentMethodToken()
	 * @param {object} options
	 * @returns {object}
	 */
	serializeACHFormData: (options) => {
		const isRoutingNumber = options[2]['value'].replace(/\D/g, '');

		if (/^\d{9}$/.test(isRoutingNumber)) {

			return {
			name: options[0]['value'],
			accountType: options[1]['value'],
			accountNumber: options[3]['value'],
			routingNumber: options[2]['value']
			}
		} else {
			return {
			name: options[0]['value'],
			accountType: options[1]['value'],
			accountNumber: options[2]['value'],
			routingNumber: options[4]['value']
			}
		}
	},

	/**
	 * Maps all card data to be sent to the API call
	 */
	mapCardData: (data) => ({
		Order: {
			Account: {
				Type: data.accountType,
				Number: data.cardNumber.replace(/\s+/g, ''),
				Cvv2: data.cvv,
				ExpireDate: data.expiration.replace(/\//g, '')
			},
			AccountHolder: {
				Name: data.name,
				BillingAddress: {
					Zip: data.zipcode
				}
			}
		}
	}),

	/**
	 * Maps all ach data to be sent to the API call
	 */
	mapACHData: (data) => ({
		Order: {
			Account: {
				Type: data.accountType,
				Number: data.accountNumber.replace(/\s+/g, ''),
				RoutingNumber: data.routingNumber.replace(/\s+/g, '')
			},
			AccountHolder: {
				Name: data.name,
				BillingAddress: {
					Country: 'USA' // ACH is only allowed for US merchants
				}
			}
		}	}),

	/**
	 * Return Payment Method Token from Stack Pay API
	 * @param {object} options
	 * @returns Promise<Object>
	 */
	getPaymentMethodToken: (options) => {
		return new Promise((resolve, reject) => {
			const type = options[1]['value']
			let serializedData;
			let data;

			if (['checking', 'savings'].includes(type)) {
				serializedData = StackPay.serializeACHFormData(options)
				data = StackPay.mapACHData(serializedData)
			} else {
				serializedData = StackPay.serializeFormData(options)
				data = StackPay.mapCardData(serializedData)
			}

			let apiKey = StackPay.getApiKey()

			return fetch(StackPay.getApiUrl() + `/api/token`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json;charset=utf-8',
					Authorization: `Bearer ${apiKey}`
				},
				body: JSON.stringify(data)
			})
				.then(async result => await result.json())
				.then(data => {
					if (data.error_code === 403) {
						Object.keys(data.errors).forEach(error => {
							resolve({
								status: 'failure',
								code: data.error_code,
								error: data.errors[error][0]
							})
						})
					} else if (data.error_code === 304 || data.error_code === 400) {
						resolve({
							status: 'failure',
							code: data.error_code,
							error: data.error_message
						})

					} else if (data.error_code === 2) {
						resolve({
							status: 'failure',
							code: data.error_code,
							error: data.error_message
						})

					} else if (data.error_code === 301) {
						resolve({
							status: 'failure',
							code: data.error_code,
							error: 'The payment gateway was unable to process the request. Please try again in a moment.'
						})

					} else if (data.hasOwnProperty('Header')) {
						StackPay.setTokenValue(data.Body.Token)
						resolve({
							hash: data.Header.Security.Hash,
							hashMethod: data.Header.Security,
							status: data.Body.Status === 1
								? 'success'
								: 'failure',
							token: data.Body.Token,
							paymentMethod: data.Body.PaymentMethod,
						})
					} else {
						resolve({
							status: 'failure',
							error: `The payment information entered failed to create a payment token and returned an un-mapped error code. Error code ${data.error_code}.`
						})
					}

				}).catch(error => reject(error))
		})

	},

	/**
	 * Return Merchant Settings from Stack Pay API
	 * @param {string} merchant_id
	 * @returns Promise<Object>
	 */
	fetchMerchantSettings: (merchant_id) => {
		return new Promise((resolve, reject) => {
			const mid = merchant_id

			let apiKey = StackPay.getApiKey()

			return fetch(StackPay.getApiUrl() + `/api/merchant-settings/${mid}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json;charset=utf-8',
					Authorization: `Bearer ${apiKey}`
				}
			})
				.then(async result => await result.json())
				.then(data => {
					if (data.error_code === 403) {
						Object.keys(data.errors).forEach(error => {
							resolve({
								status: 'failure',
								code: data.error_code,
								error: data.errors[error][0]
							})

						})

					} else if (data.error_code === 304 || data.error_code === 400) {
						resolve({
							status: 'failure',
							code: data.error_code,
							error: data.error_message
						})

					} else if (data.hasOwnProperty('Header')) {
						StackPay.setCardTypesAllowedValue(data.Body.CardTypesAllowed)
						resolve({
							hash: data.Header.Security.Hash,
							hashMethod: data.Header.Security,
							cardTypesAllowed: data.Body.CardTypesAllowed
						})

					} else {
						resolve({
							status: 'failure',
							error: 'Unable to fetch merchant settings'
						})
					}

				}).catch(error => reject(error))
		})
	},

	/**
	 * Method related to the input elements
	 */
	elements: {

		/**
		 * Create Element
		 * @param {string} elType either 'card' or 'ach' HTML template
		 * @param {object} init json object containing style configuration
		 * @returns {Promise<void>}
		 */
		create: (elType, init) => {
			return new Promise((resolve, reject) => {
				let sp = StackPay.elements
				switch (elType) {
					case 'card':
						sp.createCardElement()
							.then(iframe => {
								StackPay.elements.mountCardTo('form-container', init)
								StackPay.setSpMessenger()
								// modify init object.
								init.serverUrl = StackPay.getServerUrl()
								init.apiUrl = StackPay.getApiUrl()
								init.apiKey = StackPay.getApiKey()
								init.mode = StackPay.getEnv()

								resolve({iframe, init})
							})
						break;
					case 'card-v2':
						sp.createCardV2Element()
							.then(iframe => {
								StackPay.elements.mountCardV2To('form-container-card-v2', init)
								StackPay.setSPCardV2Messenger()
								// modify init object.
								init.serverUrl = StackPay.getServerUrl()
								init.apiUrl = StackPay.getApiUrl()
								init.apiKey = StackPay.getApiKey()
								init.mode = StackPay.getEnv()

								resolve({iframe, init})
							})
						break;
					case 'ach':
						sp.createAchElement()
							.then(iframe => {
								StackPay.elements.mountACHTo('form-container-ach', init)
								StackPay.setSpACHMessenger()
								// modify init object.
								init.serverUrl = StackPay.getServerUrl()
								init.apiUrl = StackPay.getApiUrl()
								init.apiKey = StackPay.getApiKey()
								init.mode = StackPay.getEnv()

								resolve({iframe, init})
							})
						break;
					case 'eCheck-v2':
						sp.createECheckV2Element()
							.then(iframe => {
								StackPay.elements.mountECheckV2To('form-container-echeck-v2', init)
								StackPay.setSPECheckV2Messenger()
								// modify init object.
								init.serverUrl = StackPay.getServerUrl()
								init.apiUrl = StackPay.getApiUrl()
								init.apiKey = StackPay.getApiKey()
								init.mode = StackPay.getEnv()

								resolve({iframe, init})
							})
						break;
					case 'verified-ach':
						sp.createVerifiedAchElement()
							.then(iframe => {
								StackPay.elements.mountVerifiedACHTo('form-container-verified-ach', init)
								StackPay.setSpVerifiedACHMessenger()
								// modify init object.
								init.serverUrl = StackPay.getServerUrl()
								init.apiUrl = StackPay.getApiUrl()
								init.apiKey = StackPay.getApiKey()
								init.mode = StackPay.getEnv()
								init.stripeKey = StackPay.getStripeKey()

								resolve({iframe, init})
							})
						break;
				}


			})
		},

		/**
		 * Create Card Element and fetch Required HTML
		 * @returns {Promise<HTMLObjectElement>}
		 */
		createCardElement: () => {
			return new Promise((resolve, reject) => {
				const iframe = iframeBuild(StackPay.getServerUrl() + '/card.html', 'card')
				return resolve(iframe)
			}).catch(error => console.error('CreateCardElementError: [critical]: ', error ))
		},


		/**
		 * Create Card V2 Element and fetch Required HTML
		 * @returns {Promise<HTMLObjectElement>}
		 */
		createCardV2Element: () => {
			return new Promise((resolve, reject) => {
				const iframe = iframeBuild(StackPay.getServerUrl() + '/card.v2.html', 'card-v2')
				return resolve(iframe)
			}).catch(error => console.error('CreateCardV2ElementError: [critical]: ', error ))
		},

		/**
		 * Create ACH Element and fetch required HTML
		 * @returns {Promise<HTMLObjectElement>}
		 */
		createAchElement: () => {
			return new Promise((resolve, reject) => {
				const iframe = iframeBuild(StackPay.getServerUrl() + '/ach.html', 'ach')
				return resolve(iframe)
			}).catch(error => console.error('CreateACHElementError: [critical]: ', error ))
		},

		/**
		 * Create E-Check v2 Element and fetch required HTML
		 * @returns {Promise<HTMLObjectElement>}
		 */
		createECheckV2Element: () => {
			return new Promise((resolve, reject) => {
				const iframe = iframeBuild(StackPay.getServerUrl() + '/echeck.v2.html', 'echeck-v2')
				return resolve(iframe)
			}).catch(error => console.error('CreateECheckV2ElementError: [critical]: ', error ))
		},

		/**
		 * Create ACH Element and fetch required HTML
		 * @returns {Promise<HTMLObjectElement>}
		 */
		createVerifiedAchElement: () => {
			return new Promise((resolve, reject) => {
				const iframe = iframeBuild(StackPay.getServerUrl() + '/verified-ach.html', 'verified-ach')
				return resolve(iframe)
			}).catch(error => console.error('CreateVerifiedACHElementError: [critical]: ', error ))
		},

		/**
		 * This method mounts the iframe into the containing Div 'form-container'
		 * Once mounted, then it sends the style Object ( of exists ) over to the iframe
		 * @param {string} formContainerId
		 * @param {object} init json object containing style configuration
		 * @returns {{formContainer: HTMLElement, iframe: *}}
		 */
		mountCardTo: (formContainerId, init = {}) => {
			let formContainer = document.getElementById(formContainerId)
			if (!formContainer.appendChild(StackPay.getCardIframe()) instanceof HTMLElement) {
				throw new Error('The card iframe element was not appended')
			}

			StackPay.setIframeStyles(init.styles)
			StackPay.styleCardWindow(init.styles)
			return StackPay.getCardIframe()

		},


		/**
		 * This method mounts the iframe into the containing Div 'form-container-card-v2'
		 * Once mounted, then it sends the style Object ( of exists ) over to the iframe
		 * @param {string} formContainerId
		 * @param {object} init json object containing style configuration
		 * @returns {{formContainer: HTMLElement, iframe: *}}
		 */
		mountCardV2To: (formContainerId, init = {}) => {
			let formContainer = document.getElementById(formContainerId)
			if (!formContainer.appendChild(StackPay.getCardV2Iframe()) instanceof HTMLElement) {
				throw new Error('The card v2 iframe element was not appended')
			}

			StackPay.setIframeStyles(init.styles)
			StackPay.styleCardWindow(init.styles)
			return StackPay.getCardV2Iframe()

		},

		/**
		 * This method mounts the iframe into the containing Div 'form-container-ach'
		 * Once mounted, then it sends the style Object ( of exists ) over to the iframe
		 * @param {string} formContainerId
		 * @param {object} init json object containing style configuration
		 * @returns {{formContainer: HTMLElement, iframe: *}}
		 */
		mountACHTo: (formContainerId, init = {}) => {
			let formContainer = document.getElementById(formContainerId)
			if (!formContainer.appendChild(StackPay.getAchIframe()) instanceof HTMLElement) {
				throw new Error('The ach iframe element was not appended')
			}

			StackPay.setIframeStyles(init.styles)
			StackPay.styleCardWindow(init.styles)
			return StackPay.getAchIframe()
		},

		/**
		 * This method mounts the iframe into the containing Div 'form-container-echeck-v2'
		 * Once mounted, then it sends the style Object ( of exists ) over to the iframe
		 * @param {string} formContainerId
		 * @param {object} init json object containing style configuration
		 * @returns {{formContainer: HTMLElement, iframe: *}}
		 */
		mountECheckV2To: (formContainerId, init = {}) => {
			let formContainer = document.getElementById(formContainerId)
			if (!formContainer.appendChild(StackPay.getECheckV2Iframe()) instanceof HTMLElement) {
				throw new Error('The E-Check V2 iframe element was not appended')
			}

			StackPay.setIframeStyles(init.styles)
			StackPay.styleCardWindow(init.styles)
			return StackPay.getECheckV2Iframe()
		},

		/**
		 * This method mounts the iframe into the containing Div 'form-container-ach'
		 * Once mounted, then it sends the style Object ( of exists ) over to the iframe
		 * @param {string} formContainerId
		 * @param {object} init json object containing style configuration
		 * @returns {{formContainer: HTMLElement, iframe: *}}
		 */
		mountVerifiedACHTo: (formContainerId, init = {}) => {
			let formContainer = document.getElementById(formContainerId)
			if (!formContainer.appendChild(StackPay.getVerifiedAchIframe()) instanceof HTMLElement) {
				throw new Error('The verified ach iframe element was not appended')
			}

			StackPay.setIframeStyles(init.styles)
			StackPay.styleCardWindow(init.styles)
			return StackPay.getVerifiedAchIframe()
		},

		/**
		 * Name can have letters, spaces, dashes, comma, and period, with no numbers, and no special characters.
		 * At least 1 letter.
		 * @param {string} text
		 * @returns {Promise<Boolean>}
		 */
		validateName: (text) => {
			return new Promise((resolve, reject) => {
				let result = StackPay.nameRegex.test(text)

				// Set name validity in order to determine whether to submit the API call for Payment Token
				StackPay.setNameValidity(result)
				resolve(result)
			})
		},

		/**
		 * Using Regex strings to validate designated card numbers
		 * @param {string} cardNumber
		 * @returns {Promise<Object>}
		 */
		validateCardNumber: (cardNumber) => {
			return new Promise((resolve, reject) => {
				if (StackPay.elements.validateVisa(cardNumber)) {
					resolve({
						type: 'visa',
						result: true
					})

				} else if (StackPay.elements.validateMasterCard(cardNumber)) {
					resolve({
						type: 'mastercard',
						result: true
					})

				} else if (StackPay.elements.validateAmex(cardNumber)) {
					resolve({
						type: 'amex',
						result: true
					})

				} else if (StackPay.elements.validateDinersCard(cardNumber)) {
					resolve({
						type: 'diners',
						result: true
					})

				} else if (StackPay.elements.validateDiscover(cardNumber)) {
					resolve({
						type: 'discover',
						result: true
					})

				} else if (StackPay.elements.validateJCB(cardNumber)) {
					resolve({
						type: 'jcb',
						result: true
					})

				} else {
					resolve({
						type: undefined,
						result: false
					})
				}

			})

		},

		/**
		 * Account number must only contain numbers
		 * @param {string} text
		 * @returns {Promise<Boolean>}
		 */
		validateAccountNumber: (text) => {
			return new Promise((resolve) => {
				let result = StackPay.accountNumberRegex.test(text)

				// Set account number validity in order to determine whether to submit the API call for Payment Token
				StackPay.setAccountNumberValidity(result)
				resolve(result)
			})
		},

		/**
		 * Account number verify must be same as account number
		 * @param {string} text
		 * @returns {Promise<Boolean>}
		 */
		validateAccountNumberVerify: (text, accNum) => {
			return new Promise((resolve) => {
				let result = (accNum && text === accNum)

				StackPay.setAccountNumberVerifyValidity(result)
				resolve(result)
			})
		},

		/**
		 * Routing numbers are 9 digits
		 * @param {string} text
		 * @returns {Promise<Boolean>}
		 */
		validateRoutingNumber: (text) => {
			return new Promise((resolve) => {
				let result = StackPay.routingNumberRegex.test(text)

				// Set account number validity in order to determine whether to submit the API call for Payment Token
				StackPay.setRoutingNumberValidity(result)
				resolve(result)
			})
		},

		/**
		 * Validates Visa cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateVisa: (cardNumber) => {
			return StackPay.visaRegex.test(cardNumber)
		},

		/**
		 * Validates MasterCard cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateMasterCard: (cardNumber) => {
			return StackPay.masterCardRegex.test(cardNumber)
		},

		/**
		 * Validates Amex cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateAmex: (cardNumber) => {
			return StackPay.amexRegex.test(cardNumber)
		},

		/**
		 * Validates Discover cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateDiscover: (cardNumber) => {
			return StackPay.discoverRegex.test(cardNumber)
		},

		/**
		 * Validates Diners cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateDinersCard: (cardNumber) => {
			return StackPay.dinersRegex.test(cardNumber)
		},

		/**
		 * Validates JCB cards and returns boolean
		 * @param {string} cardNumber
		 * @returns {boolean}
		 */
		validateJCB: (cardNumber) => {
			return StackPay.jcbRegex.test(cardNumber)
		},

		/**
		 * Expiration date validation
		 * month must be between 1 & 12 inclusive
		 * year must be greater than current year
		 * Month must be greater than current month if entered year === current year
		 * Entered Month can be less than current month as long as Entered Year is greater than Current Year		 *
		 * @param {string} expiration
		 * @returns {Promise<Boolean>}
		 */
		validateCardExpiration: (expiration) => {
			return new Promise((resolve, reject) => {
				// check that month & year are digits
				let validated = StackPay.elements.expirationValidDigits(expiration.trim())

				// obtain the entered month
				let enteredMonth = validated === true
					? parseInt(expiration.substring(0, 2), 10)
					: 0

				// current month
				let currentMonth = new Date().getMonth()

				// obtain entered year
				let enteredYear = validated === true
					? parseInt('20' + expiration.substring(2, 4), 10)
					: 0
				// current year
				let currentYear = new Date().getFullYear()

				// if Entered Year > Current Year, then Entered Month === Current Month
				let sameMonthGreaterEnteredYear = enteredYear > currentYear && enteredMonth === currentMonth

				// if Entered Year > Current Year && Entered Month < Current Month
				let greaterEnteredYearLesserEnteredMonth = enteredYear > currentYear && enteredMonth < currentMonth

				// If Entered Year >= Current Year, then Entered Month > Current Month
				let greaterEnteredYearGreaterMonth = enteredYear >= currentYear && enteredMonth > currentMonth

				// check that month is between 1 & 12 inclusive
				let monthBetweenOneAndTwelve = enteredMonth >= 1 && enteredMonth <= 12

				let result = sameMonthGreaterEnteredYear ||
					greaterEnteredYearLesserEnteredMonth ||
					greaterEnteredYearGreaterMonth &&
					monthBetweenOneAndTwelve

				// Set validity of entered expiration in order to determine whether to submit API call
				StackPay.setExpirationValidity(result)

				resolve(StackPay.getExpirationValidity())
			}).catch(error => {
				console.log('Caught Exception validateCardExpiration(): ', error)
				// throw new Error(error)
			})
		},

		/**
		 * Expiration Date must be 4 numerical digits
		 * @param {string} expiration
		 * @returns {Object}
		 */
		expirationValidDigits: (expiration) => {
			return StackPay.monthYearRegex.test(expiration)
		},

		/**
		 * CVV Must be either 3 or 4 (if AMEX ) numerical digits
		 * @param {string} cvv
		 * @param {string} cardType
		 * @returns {Promise<Boolean>}
		 */
		validateCVV: (cvv, cardType) => {
			let validated;
			return new Promise((resolve, reject) => {
				let cardTypeExists = cardType !== '' && cardType !== null
				let cvvExists = cvv !== '' && cvv !== null
				let exists = cvvExists && cardTypeExists

				if (!cvvExists) {
					reject({type: 'cvv', result: 'CVV is required'})
				}

				if (!cardTypeExists) {
					reject({type: 'card', result: 'CVV validation requires a Card Number'})
				}

				validated = cardType === 'amex'
					? StackPay.cvvAmex.test(cvv)
					: StackPay.cvvRegex.test(cvv)

				// Set CVV Validity in order to determine whether to submit API call for payment token
				StackPay.setCvvValidity(validated)

				resolve(validated)
			})
		},

		/**
		 * Determine validity of zip code using regex string
		 * Sets also zipCodeValid variable.
		 * @param {string} zipcode
		 * @returns {Promise<Boolean>}
		 */
		validateZipCode: (zipcode) => {
			return new Promise((resolve, reject) => {
				// Set Zip Code Validity in order to determine whether to submit API call for payment token
				StackPay.setZipCodeValidity(StackPay.zipCodeRegex.test(zipcode))
				resolve(StackPay.zipCodeRegex.test(zipcode))
			})
		},

		/**
		 * Determine validity of email address using regex string
		 * Sets also emailValid variable.
		 * @param {string} email
		 * @returns {Promise<Boolean>}
		 */
		validateEmail: (email) => {
			return new Promise((resolve, reject) => {
				// Set Zip Code Validity in order to determine whether to submit API call for payment token
				StackPay.setEmailAddressValidity(StackPay.emailRegex.test(email))
				resolve(StackPay.emailRegex.test(email))
			})
		},
	},

}
