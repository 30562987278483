/**
 * Builds the iframe, and injects the src url that will do all API processing.
 * @param iframeUrl
 * @returns {HTMLIFrameElement}
 */
export const iframeBuild = (iframeUrl, type) => {
	let iframe = document.createElement('iframe');
	iframe.setAttribute('src', iframeUrl);
	iframe.setAttribute('id', 'sp-js-api')
	iframe.setAttribute('scrolling', 'no')
	//iframe.style.minWidth = '500px';
	// iframe.style.minHeight = '550px';
	iframe.allowTransparency = true
	iframe.tabIndex = 0;
	// iframe.style.border = 'none';
	iframe.style.overflow = 'hidden';
	iframe.style.userSelect = 'none';
	iframe.classList.add('embed-responsive-item')
	iframe.classList.add('border-0')

	/** Set the iframe object into the StackPay instance **/
	if (type === 'card') {
		return StackPay.setCardIframe(iframe)
	}
	if (type === 'card-v2') {
		return StackPay.setCardV2Iframe(iframe)
	}
	if (type === 'echeck-v2') {
		return StackPay.setECheckV2Iframe(iframe)
	}
	if (type === 'verified-ach') {
		return StackPay.setVerifiedAchIframe(iframe)
	}

	return StackPay.setAchIframe(iframe)
}

/**
 * sends style object to iframe via postMessage
 * @param {object} styles
 * @param {HTMLIFrameElement} iframe
 * @returns {object} styles
 */
export const postStylesToIframe = (styles, iframe) => {
	iframe.contentWindow.postMessage(styles, StackPay.getServerUrl())
	return styles
}

export default iframeBuild



