/**
 * Creates a messenger between two windows
 *  which have two different domains
 */
export class IframeMessenger {

	/**
	 *
	 * @param {object} remoteWindow - window object of the remote window
	 * @param {string} remoteDomain - domain of the remote window
	 * @param {object} eventHandlers - all the event names and handlers
	 */
	constructor(remoteWindow, remoteDomain, eventHandlers = {}) {
		this.remoteWindow  = remoteWindow
		this.remoteDomain = remoteDomain
		this.eventHandlers = eventHandlers

		window.addEventListener("message", (e) => this.receive.call(this, e));
	}

	/**
	 * Post information to a cross-origin iframe
	 * @param event
	 * @param {any} data
	 */
	post (event, data) {
		try {
			// data obj should have event name
			let json = JSON.stringify({ event, data })
			this.remoteWindow.postMessage(json, this.remoteDomain)

		} catch (e) {}
	}

	/**
	 * Receive data from a cross-origin iframe within a message event handler
	 * @param {Event} event
	 */
	receive(event) {
		let json;
		try {
			json = JSON.parse(event.data ? event.data : '{}');
		} catch (e) {
			return;
		}
		let eventName = json.event,
			data = json.data;

		if (event.origin !== this.remoteDomain)
			return;

		if (typeof this.eventHandlers[eventName] === "function")
			this.eventHandlers[eventName](data);
	}

}

export default IframeMessenger
