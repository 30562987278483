/**
 * Create Stylesheet and append it to the head element of the page
 * @returns {StyleSheet}
 */
const createStyleSheet = () => {
	let style = document.createElement('style')
	style.setAttribute('media', 'screen')

	// Webkit hack:
	style.appendChild(document.createTextNode(''))

	document.head.appendChild(style)
	return style.sheet
}

/**
 * add Rules to the dynamically created Stylesheet
 * @param {string} sheet - Stylesheet that is generated by createStyleSheet()
 * @param {string} selector - selector that styles will be attached to
 * @param {string} rules - style rules that will style the selector
 * @param {Integer} index - stylesheet index
 */
const addCSSRule = (sheet, selector, rules, index) => {
	if("insertRule" in sheet) {
		sheet.insertRule(selector + "{" + rules + "}", index);
	}

	else if("addRule" in sheet) {
		sheet.addRule(selector, rules, index);
	}
}

/**
 * Iterate through the injected Style object to style the payment form
 * @param {object} styles - style object that contains base, inputs, and invalid nodes
 */
export const templateStyles = (styles = {}) => {


	let base = styles.base
	let inputs = styles.inputs
	let styleSheet = createStyleSheet()

	// Styles for base container
	Object.keys(base).forEach((selector) => {
		Object.keys(base[selector]).forEach(style => {
			addCSSRule(styleSheet, selector,  style + ': ' + base[selector][style])
		})

	})

	// Calculate Styles for inputs
	Object.keys(inputs).forEach(selector => {
		Object.keys(inputs[selector]).forEach(style => {
			addCSSRule(styleSheet, selector, style + ': ' +  inputs[selector][style])

		})
	})
}

export default templateStyles;
